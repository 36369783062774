import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import SimilarCard from '../../../components/similar.card';

import imgSEO from '../../../assets/img/services/intranet.jpg';

import similar1 from '../../../assets/img/services/thumbnail/formations.jpg';
import similar2 from '../../../assets/img/services/thumbnail/dev-mobile.jpg';
import similar3 from '../../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/online_friends.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Création de site intranet/extranet | Agence digitale | MDS Digital Agency"
      description="Vous souhaitez sécuriser le partage de données avec vos clients, vos fournisseurs ou votre équipe ? MDS développe pour vous un site intranet/extranet."
      keywords="site intranet/extranet"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Site intranet/extranet </h2>
      <p>
        Vous souhaitez sécuriser le partage de données, de dossiers ou d’informations avec vos
        clients, vos fournisseurs ou votre équipe ?{' '}
        <strong>MDS développe pour vous des modules personnalisés</strong> intégrés à votre site
        intranet/extranet.
      </p>
      <p>
        <strong>Pour partager des informations internes</strong> à votre entreprise et permettre à
        vos collaborateurs de s’échanger en toute sécurité des fichiers et informations par
        l’intermédiaire d’une interface web, MDS développe pour vous un module{' '}
        <strong>intranet</strong>, éventuellement intégré à votre site web.
      </p>
      <p>
        <strong>Pour</strong> que <strong>des clients, fournisseurs ou partenaires externes</strong>{' '}
        soient authentifiés et puissent accéder ou échanger de manière sécurisée des documents ou
        informations avec votre entreprise, MDS vous propose un module extranet personnnalisé,
        éventuellement intégré à votre site web.
      </p>
      <p>
        Voici <strong>étape par étape</strong> comment MDS vous accompagne dans{' '}
        <strong>la création de votre site intranet/extranet</strong> :
      </p>
      <ul>
        <li>Nous procédons à une analyse fine de vos besoins</li>
        <li>
          Nous établissons ensemble le profil des utilisateurs et le type de données à échanger
        </li>
        <li>Nous réfléchissons aux fonctionnalités utiles pour ces échanges</li>
        <li>Nos développeurs prennent connaissance des contraintes techniques existantes</li>
        <li>
          Notre équipe conçoit votre site intranet/extranet en tenant compte de{' '}
          <AniLink cover bg="#fba515" to="/services/graphic-design/">
            l’identité visuelle
          </AniLink>{' '}
          de votre activité.
        </li>
        <li>Nous nous chargeons du déploiement et de la maintenance</li>
      </ul>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Besoin d’une plateforme moderne et personnalisée pour communiquer avec vos collaborateurs?"
        subtitle="N'hésitez plus"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Formations utilisateurs" url="/services/formations/" img={similar1} />
          <SimilarCard
            text="Développement applications mobile"
            url="/services/developpement-mobile/"
            img={similar2}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar3}
          />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
